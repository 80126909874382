import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const CaseStudiesAPI = createApi({
    reducerPath: 'CaseStudiesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['CaseStudies'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getCaseStudies: builder.query({
            query: (path) => path,
            providesTags: ['CaseStudies'],
            invalidatesTags:['CaseStudies']
        }),
        getRandomCaseStudies : builder.query({
            query: (path) => path,
            providesTags: ['CaseStudies'],
            invalidatesTags:['CaseStudies']
        }),
        

    }),
});

export const { useGetCaseStudiesQuery , useGetRandomCaseStudiesQuery} = CaseStudiesAPI;